import React, { PureComponent } from 'react';

class EngagementCard extends PureComponent {

  render() {
    return (
      <div className="card">
        <div className="card-header card-header-bb">
          <div className="card-text">
            <p className="card-title card-title-s">Average User Engagement</p>
          </div>
        </div>
        <div className="card-body">
          <canvas id="chartActivity" />
        </div>
        <div className="card-footer ">
          <div className="legend">
            <i className="fa fa-circle text-info" /> Data 1
            <i className="fa fa-circle text-danger" /> Data 2
          </div>
          <hr />
          <div className="stats">
            <i className="fa fa-check" /> Data information certified
          </div>
        </div>
      </div>
    );
  }
}

export default EngagementCard;