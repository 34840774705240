/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import shortName from 'helpers/shortName';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

export class UserInfo extends PureComponent {
  render() {
    const { user } = this.props;
    const firstname = user && user.firstname;
    const lastname = user && user.lastname;
    const abrName = shortName(`${firstname} ${lastname}`);
    return (
      <UncontrolledDropdown className="btn-rotate" nav>
        <DropdownToggle
          aria-haspopup
          caret
          color="default"
          data-toggle="dropdown"
          id="navbarDropdownMenuLink"
          nav
        >
          <div className="profile profile-initial">
            <span className="profile-initial-txt">{abrName}</span>
          </div>
          <span>{firstname}</span>
        </DropdownToggle>
        <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-notif" right>
          <div className="account-info">
            <p className="account-name">{firstname}</p>
            <p className="account-role">{lastname}</p>
          </div>
          <hr />
          <a className="dropdown-item" href="#"><i className="nc-icon nc-settings" />Kontoinställningar</a>
          <a className="dropdown-item" href="#modal-support" data-toggle="modal" data-target="#modal-support">
            <i className="nc-icon nc-support-17" /> Synpunkter / Support
          </a>
          <a className="dropdown-item" href="MyNotifications">
            <i className="nc-icon nc-chat-33" /> Mina bevakningar
          </a>
          <a className="dropdown-item" href="#"><i className="nc-icon nc-box" /> Mina ordrar</a>
          <hr className="mt-0" />
          <a className="dropdown-item" href="/logout"><i className="nc-icon nc-button-power" /> Logga ut</a>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default UserInfo;
