import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import UserCard from './widgets/UserCard';
import CartCard from './widgets/CartCard';
import RegisterCard from './widgets/RegisterCard';
import CurrentLoginCard from './widgets/CurentLoginCard';
import LatestDocCard from './widgets/LatestDocsCard';
import ProjectsCard from './widgets/ProjectsCard';
import DeviceCard from './widgets/DeviceCard';
import EngagementCard from './widgets/EngagementCard';

class Dashboard extends PureComponent {

  render() {
    return (
      <>
        <Row>
          <Col lg="3" md="6" sm="6">
            <UserCard />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CartCard />
          </Col>
          <Col lg="3" md="6" sm="6">
            <RegisterCard />
          </Col>
          <Col lg="3" md="6" sm="6">
            <CurrentLoginCard />
          </Col>
        </Row>

        <Row>
          <Col className="col-md-3">
            <LatestDocCard />
          </Col>
          <Col className="col-md-6">
            <ProjectsCard />
          </Col>
          <Col className="col-md-3">
            <DeviceCard />
            <EngagementCard />
          </Col>
        </Row>
      </>
    );
  }
}

export default Dashboard;