import React from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import { Callback } from '../components/auth/callback';
import { Logout } from '../components/auth/logout';
import { LogoutCallback } from '../components/auth/logoutCallback';

import { PrivateRoute } from '../routes/privateRoute';
import { SilentRenew } from '../components/auth/silentRenew';
import PrivatePage from '../components/privatePage';

import LandingPage from '../components/demo/LandingPage';
import LoginPage from '../components/demo/LoginPage';
import DashDemoPage from '../components/demo/DashboardPage';
import BestallaPage from '../components/demo/BestallaPage';
import KassaPage from '../components/demo/KassaPage';
import NastaPage from '../components/demo/NastaPage';
import ThankYouPage from '../components/demo/ThankYouPage';
import ProductPage from '../components/demo/ProductsPage';
import MyTest from '../components/demo/myTest';

import DemoLayout from './DemoLayout';
import BlankLayout from './BlankLayout';
import MainLayout from './MainLayout';
import KommunalLayout from './KommunalLayout';

import Dashboard from '../components/dashboard/Dashboard';


export class Routes extends React.Component {
  render() {
    const { user, location } = this.props;
    return (
      <Switch>
        <Route
          path="/test"
          exact
          render={matchProps => (
            <BlankLayout>
              <MyTest {...matchProps} />
            </BlankLayout>
          )}
        />
        <Route
          path="/"
          exact
          render={matchProps => (
            <BlankLayout>
              <LandingPage {...matchProps} />
            </BlankLayout>
          )}
        />

        <Route
          path="/login"
          exact
          render={matchProps => (
            <BlankLayout>
              <LoginPage {...matchProps} />
            </BlankLayout>
          )}
        />

        <Route
          path="/dashboard"
          exact
          render={matchProps => (
            <DemoLayout>
              <DashDemoPage {...matchProps} />
            </DemoLayout>
          )}
        />

        <Route
          path="/bestalla"
          exact
          render={matchProps => (
            <DemoLayout>
              <BestallaPage {...matchProps} />
            </DemoLayout>
          )}
        />

        <Route
          path="/kassa"
          exact
          render={matchProps => (
            <DemoLayout>
              <KassaPage {...matchProps} />
            </DemoLayout>
          )}
        />

        <Route
          path="/nasta"
          exact
          render={matchProps => (
            <DemoLayout>
              <NastaPage {...matchProps} />
            </DemoLayout>
          )}
        />

        <Route
          path="/thankyou"
          exact
          render={matchProps => (
            <DemoLayout>
              <ThankYouPage {...matchProps} />
            </DemoLayout>
          )}
        />
        
        <Route
          path="/products"
          exact
          render={matchProps => (
            <DemoLayout>
              <ProductPage {...matchProps} />
            </DemoLayout>
          )}
        />

        <Route
          path="/kommunalproducts"
          exact
          render={matchProps => (
            <KommunalLayout>
              <ProductPage {...matchProps} />
            </KommunalLayout>
          )}
        />

        <Route exact path="/signin-oidc" component={Callback} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/logout/callback" component={LogoutCallback} />
        <Route exact path="/silentrenew" component={SilentRenew} />
        <PrivateRoute path="/sign-in" component={PrivatePage} />
        {!user && location.pathname === '/app' && <Redirect to="/sign-in" />}

        {user &&
          <MainLayout user={user}>
            <Switch>
              <Route path="/app" component={Dashboard} />
            </Switch>
          </MainLayout>
        }
        
        
      </Switch>
    );
  }
}

export default withRouter(Routes);
