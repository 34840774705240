import React, { Component } from 'react';
import '../styles/App.css';

class PrivatePage extends Component {
  render() {
    return (
      <div className="App">
        this is a private page
      </div>
    );
  }
}

export default PrivatePage;
