import React, { PureComponent } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card } from 'reactstrap';
import classnames from 'classnames';

import LinkDocuments from './products/tabs/LinkDocuments';
import BusinessInformation from './products/tabs/BusinessInformation';
import ProductInformation from './products/tabs/ProductInformation';
import DynamicForm from './products/tabs/DynamicForm';
import Stocks from './products/tabs/Stocks';
import Publish from './products/tabs/Publish';

export default class productEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { product } = this.props;
    return (
      <Card className="card-tabs card-tabs-v3 mb-0">
        <Card body className="card-body-p-0">
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav tabs role="tablist">
                <NavItem className="w-auto">
                  <NavLink
                    className={classnames('py-2', { active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                    <span className="card-tabs-title">Länkade dok.</span>
                  </NavLink>
                </NavItem>
                <NavItem className="w-auto">
                  <NavLink
                    className={classnames('py-2', { active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >
                    <span className="card-tabs-title">Affärsinfo.</span>
                  </NavLink>
                </NavItem>
                <NavItem className="w-auto">
                  <NavLink
                    className={classnames('py-2', { active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                  >
                    <span className="card-tabs-title">Produktinfo.</span>
                  </NavLink>
                </NavItem>
                <NavItem className="w-auto">
                  <NavLink
                    className={classnames('py-2', { active: this.state.activeTab === '4' })}
                    onClick={() => { this.toggle('4'); }}
                  >
                    <span className="card-tabs-title">Redigerbar.</span>
                  </NavLink>
                </NavItem>
                <NavItem className="w-auto">
                  <NavLink
                    className={classnames('py-2', { active: this.state.activeTab === '5' })}
                    onClick={() => { this.toggle('5'); }}
                  >
                    <span className="card-tabs-title">Lager.</span>
                  </NavLink>
                </NavItem>
                <NavItem className="w-auto">
                  <NavLink
                    className={classnames('py-2', { active: this.state.activeTab === '6' })}
                    onClick={() => { this.toggle('6'); }}
                  >
                    <span className="card-tabs-title">Visning.</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </Card>
        <TabContent activeTab={this.state.activeTab} className="tab-content px-3 py-3">
          <TabPane tabId="1">
            <LinkDocuments product={product} />
          </TabPane>
          <TabPane tabId="2">
            <BusinessInformation />
          </TabPane>
          <TabPane tabId="3">
            <ProductInformation product={product} />
          </TabPane>
          <TabPane tabId="4">
            <DynamicForm />
          </TabPane>
          <TabPane tabId="5">
            <Stocks product={product} />
          </TabPane>
          <TabPane tabId="5">
            <Publish />
          </TabPane>
        </TabContent>
      </Card>
    );
  }
}
