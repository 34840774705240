import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';

class ForHands extends PureComponent {

  render() {
    return (
      <Row className="no-gutters">
        <Col md="4">
          <div className="dynamic-img-info dynamic-info-redigerbar">
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Namn *" />
              <label>Namn *</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Titel" />
              <label>
                Titel
                <span style={{ color: '#7a736c' }}>&nbsp;&nbsp;|&nbsp;&nbsp;T.ex.: Vaktmästare</span>
              </label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Telefon *" />
              <label>Telefon *</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Mobil" />
              <label>Mobil</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Email *" />
              <label>Email *</label>
            </div>
            <div className="form-group">
              <select name="" id="" className="form-control fs-12">
                <option defaultValue="">Advelning</option>
              </select>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Sektion" />
              <label>Sektion</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Address *" />
              <label>Address *</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Gods" />
              <label>Gods</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Box" />
              <label>Box</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Sektion" />
              <label>Sektion</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Address *" />
              <label>Address *</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Gods" />
              <label>Gods</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Box" />
              <label>Box</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Sektion" />
              <label>Sektion</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Address *" />
              <label>Address *</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Gods" />
              <label>Gods</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Box" />
              <label>Box</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Sektion" />
              <label>Sektion</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Address *" />
              <label>Address *</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Gods" />
              <label>Gods</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Sektion" />
              <label>Sektion</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Address *" />
              <label>Address *</label>
            </div>
            <div className="form-group floating-label floating-label-sm fs-12">
              <input type="text" className="form-control" placeholder="Gods" />
              <label>Gods</label>
            </div>
          </div>
          <div className="dynamic-img-footer">
            <a href="#test" className="mt-3 fs-12"><i className="far fa-file-pdf mr-1" /> Visa PDF</a>
            <a href="#test" className="btn btn-primary btn-sm btn-normal-f m-0 pull-right">Spara</a>
            <a href="#test" className="btn btn-primary btn-sm btn-normal-f m-0 mr-1 pull-right">Lägg till Fält</a>
          </div>
        </Col>
        <Col md="8">
          <div className="edit-task-wrap">
            <div className="edit-task-box">
              <h5>Redigera uppgifter</h5>
              <p className="fs-12 mb-1">Ange uppgifter nedan för markerat fält.</p>
              <p className="fs-12 mb-3">Klicka på spara när du är färdig.</p>
              <div className="form-group row">
                <div className="col-md-6">
                  <label htmlFor="" className="fs-12">
                    Type <span className="fs-12 fw-normal ml-1">fältets typ</span>
                  </label>
                  <select name="" id="" className="form-control fs-12">
                    <option defaultValue="">Text</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="fs-12">Attribut</label>
                  <select name="" id="" className="form-control fs-12">
                    <option defaultValue="">Obligatoriskt</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="" className="fs-12">
                  Namn <span className="fs-12 fw-normal ml-1">fältnamnet som visas mot användaren</span>
                </label>
                <input type="text" className="form-control fs-12" />
              </div>
              <div className="form-group">
                <label htmlFor="" className="fs-12">
                  Variabelnamn <span className="fs-12 fw-normal ml-1">(anwänds intemt i mallen)</span>
                </label>
                <input type="text" className="form-control fs-12" />
              </div>
              <div className="form-group">
                <label htmlFor="" className="fs-12">
                  Exempeltext <span className="fs-12 fw-normal ml-1">förslag på hur fältet kan fyllas i</span>
                </label>
                <input type="text" className="form-control fs-12" />
              </div>
              <div className="form-group">
                <label htmlFor="" className="fs-12">
                  Väljare <span className="fs-12 fw-normal ml-1">klicka här för att</span>
                </label>
                <select name="" id="" className="form-control fs-12">
                  <option defaultValue="" />
                </select>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ForHands;