const documents = [
  {
    id: 1,
    name: 'Minfil.pdf',
    description: 'Materialbank',
    action: 'download',
    fileType: 'pdf'
  },
  {
    id: 2,
    name: 'Rapportmall.docx',
    description: 'Sajten Klara (Kontorstryck)',
    action: 'edit',
    fileType: 'word'
  },
  {
    id: 3,
    name: 'Rapportmall.xls',
    description: 'Sajten Klara (Kontorstryck)',
    action: 'edit',
    fileType: 'excel'
  },
  {
    id: 4,
    name: 'Minfil.pdf',
    description: 'Materialbank',
    action: 'download',
    fileType: 'pdf'
  },
  {
    id: 5,
    name: 'Rapportmall.docx',
    description: 'Sajten Klara (Kontorstryck)',
    action: 'edit',
    fileType: 'word'
  },
  {
    id: 6,
    name: 'Rapportmall.xls',
    description: 'Sajten Klara (Kontorstryck)',
    action: 'edit',
    fileType: 'excel'
  },
  {
    id: 7,
    name: 'Minfil.pdf',
    description: 'Materialbank',
    action: 'download',
    fileType: 'pdf'
  },
  {
    id: 8,
    name: 'Rapportmall.docx',
    description: 'Sajten Klara (Kontorstryck)',
    action: 'edit',
    fileType: 'word'
  },
  {
    id: 9,
    name: 'Rapportmall.xls',
    description: 'Sajten Klara (Kontorstryck)',
    action: 'edit',
    fileType: 'excel'
  },
  
];

export default documents;