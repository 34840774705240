import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, CardBody, Card, CardHeader } from 'reactstrap';

class LatestDocsCard extends PureComponent {

  render() {
    const { documents } = this.props;
    return (
      <Card className="card-latest-docs">
        <CardHeader className="card-header-bb">
          <div className="card-text">
            <p className="card-title card-title-s">Senaste dokumenten</p>
          </div>
        </CardHeader>
        <CardBody className="card-body-p-0">
          <Table>
            <tbody>
              {documents.map(doc => (
                <tr key={doc.id}>
                  <td className={`text-center doc-type doc-type-${doc.fileType}`}>
                    <i className={`far fa-file-${doc.fileType}`} />
                  </td>
                  <td>
                    <p className="doc-name">{doc.name}</p>
                    <p className="doc-title">{doc.description}</p>
                  </td>
                  <td className="doc-action">
                    <a href="#test">
                      <i className={`fas fa-${doc.action}`} />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  documents: state.account.documents,
});

export default connect(mapStateToProps)(LatestDocsCard);