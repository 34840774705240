// @ts-nocheck
import React from 'react';
import { Query } from 'react-apollo';

import meQuery from '../queries/me';

export default props =>
  <Query query={meQuery} fetchPolicy="cache-and-network">
    {response => {
      const { me } = response.data || {};

      if (!me) return null;
      return props.children({
        me: {
          ...me,
        },
        
      });
    }}
  </Query>;
