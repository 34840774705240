import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Row, Col, Nav, NavItem, NavLink, UncontrolledCollapse } from 'reactstrap';
import ModalDialog from 'components/common/dialogs/ModalDialog';
import ProductImageView from './ProductImageView'
class BestallaPage extends PureComponent {
  initialState = {
    product1: 0,
    product2: 0,
    product3: 0,
    product4: 0,
    product5: 0,
  };

  state = {
    ...this.initialState,
  };

  handleChange = ({ target: { value, name }}) => {
    this.setState({ [name]: value });
  }

  handleViewImage = product => {
    ModalDialog.show({
      component: ProductImageView,
      title: 'Redigera',
      passProps: {
        product
      }
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const { products, items } = this.props;
    const formName = event.target.name;
    const productId = formName.split('-');
    // check if exists
    const selectedProduct = products.find(product => product.id === parseInt(productId[1], 10));
    const alreadyInCart = items.find(item => item.id === selectedProduct.id);

    if (alreadyInCart) {
      const cartSelectedItemQty = parseInt(alreadyInCart.qty, 10);
      const stateQty = parseInt(this.state[`${productId[0]}${productId[1]}`], 10);
      this.props.onUpdateToCart(selectedProduct.id, { ...selectedProduct, qty: cartSelectedItemQty + stateQty });
    } else {
      this.props.onAddToCart({ ...selectedProduct, qty: parseInt(this.state[productId.join('')], 10) });
    }
    
    toastr.success('Tillagd!', 'Varukorgen är uppdaterad');
  }

  render() {
    const { products } = this.props;
    return (
      <Fragment>
        <Row>
          <Col sm="12" md="3" lg="2" className="pr-0 mt-2">
            <div className="card card-height card-multi-collapse">
              <div className="card-header card-header-bb">
                <h4 className="card-title card-title-s">Kategorier</h4>
              </div>
              <div className="card-body p-2 pt-0 mr-10">
                <Nav navbar className="nav-kategorier">
                  <NavItem caret>
                    <NavLink id="toggler">
                      Kontorstrycksaker
                      <b className="caret" />
                    </NavLink>
                    <UncontrolledCollapse toggler="#toggler" caret>
                      <Nav navbar>
                        <NavItem>
                          <NavLink>Brevpapper</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink>Konferensblock</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink>Korrkort</NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink>Visitkort</NavLink>
                        </NavItem>
                      </Nav>
                    </UncontrolledCollapse>
                  </NavItem>
                  <NavItem>
                    <NavLink>Manualer</NavLink>
                  </NavItem>
                </Nav>
                
              </div>
            </div>
          </Col>
          <div className="col-sm-12 col-md-6 col-lg-7 pr-0">
            <div className="card card-height card-category-items mb-0">
              <div className="card-header pt-0">
                <div className="row product-head-filters align-items-center flex-row">
                  <div className="col-md-3" />
                      
                  <div className="col-md-9 text-right d-flex flex-row align-items-center justify-content-end">
                    <div className="form-group mb-0 mr-3 form-group-artiklar">
                      <label htmlFor="" className="d-inline mr-1">Artiklar:</label>
                      <select name="" id="" className="form-control d-inline form-control-sm">
                        <option value="">25</option>
                      </select>
                    </div>
                    <ul className="pagination justify-content-end mr-3 mb-0">
                      <li className="page-item">
                        <a className="page-link" href="#link" aria-label="Previous">
                          <span aria-hidden="true"><i className="fa fa-angle-double-left" aria-hidden="true" /></span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#link">1</a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link" href="#link">2</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#link">3</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#link" aria-label="Next">
                          <span aria-hidden="true"><i className="fa fa-angle-double-right" aria-hidden="true" /></span>
                        </a>
                      </li>
                    </ul>
                    <div className="btn-group button-group-view">
                      <button type="button" className="btn"><i className="fa fa-th-large" /></button>
                      <button type="button" className="btn active"><i className="fa fa-th-list" /></button>
                      <button type="button" className="btn"><i className="fa fa-list" /></button>
                      <button type="button" className="btn"><i className="fa fa-align-justify" /></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {products.map(product => (
                  <div className="product-list-item" key={product.id}>
                    <div className="row align-items-center">
                      <div className="col-md-2">
                        <div className="product-list-item-img">
                          <img src={product.image} alt="" />
                          <div className="product-list-item-img-hover">
                            <a href="#view" onClick={() => this.handleViewImage(product)}>
                              <i className="fa fa-search" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h5 className="product-name">{product.name}</h5>
                        <p className="product-desc mb-1">{product.description}</p>
                        <div className="row">
                          <div className="col-md-3">
                            <dl className="product-info">
                              <dt>Art.nr</dt>
                              <dd>{product.version}</dd>
                            </dl>
                            <dl className="product-info">
                              <dt>Format</dt>
                              <dd>90x55 mm</dd>
                            </dl>
                            <dl className="product-info">
                              <dt>Omfång</dt>
                              <dd>2 sidor</dd>
                            </dl>
                          </div>
                          <div className="col-md-5">
                            <dl className="product-info">
                              <dt>Ansvarig</dt>
                              <dd>Anders Andersson</dd>
                            </dl>
                            <dl className="product-info">
                              <dt>Saldo</dt>
                              <dd>10 st</dd>
                            </dl>
                            <dl className="product-info">
                              <dt>Bevakningspunkt</dt>
                              <dd>5 st</dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <form onSubmit={this.handleSubmit} name={`product-${product.id}`}>
                          <label htmlFor="">Antal</label>
                          <input
                            type="text"
                            className="form-control text-center w-100"
                            name={`product${product.id}`}
                            onChange={this.handleChange}
                            value={this.state[`product${product.id}`]}
                          />
                          <input type="submit" className="btn btn-sm btn-primary w-100 mb-0" value="Beställ" />
                        </form>
                      </div>
                    </div>
                  </div>
                  
                ))}
                
              </div>
          
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 mt-2">
            <div className="card card-height card-news">
              <div className="card-header card-header-bb">
                <h4 className="card-title card-title-s">Nyheter</h4>
              </div>
              <div className="card-body pt-0">
                <div className="news-item">
                  <h4 className="news-title">Nyhet 1</h4>
                  <p className="news-desc">Beskrivning av nyheten kommer här. Det kan vara en fantastisk nyhet. <a href="#test">Läs mer här »</a></p>
                </div>
                <div className="news-item">
                  <h4 className="news-title">Nyhet 2</h4>
                  <p className="news-desc">Beskrivning av nyheten kommer här. Det kan vara en fantastisk nyhet. <a href="#test">Läs mer här »</a></p>
                </div>
                <div className="news-item">
                  <h4 className="news-title">Nyhet 3</h4>
                  <p className="news-desc">Beskrivning av nyheten kommer här. Det kan vara en fantastisk nyhet. <a href="#test">Läs mer här »</a></p>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  console.log('state', state);
  return {
    products: state.account.products,
    items: state.account.cart,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddToCart: product => dispatch({ type: 'uq/account/ADD_CART', payload: product }),
    onUpdateToCart: (id, item) => dispatch({ type: 'uq/account/UPDATE_CART', payload: { id, item }})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BestallaPage);