import React from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col
} from 'reactstrap';

export default class myTest extends React.Component {
  render() {
    return (
      <Row>
        <Col md="12">
          <UncontrolledDropdown>
            <DropdownToggle
              aria-expanded={false}
              aria-haspopup
              caret
              className="btn-round btn-block"
              color="primary"
              data-toggle="dropdown"
              id="dropdownMenuButton"
              type="button"
            >
              Dropdown
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="dropdownMenuButton"
              right
            >
              <DropdownItem header tag="div">
                Dropdown header
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Action
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Another action
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Something else
              </DropdownItem>
              <hr className="mb-2" />
              <a href="#test" className="workspace-edit-link"><i className="fa fa-edit" />Edit Sites</a>
            </DropdownMenu>
          </UncontrolledDropdown>
                        
        </Col>
      </Row>
    );
  }
}
