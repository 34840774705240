import React, { Component } from 'react';
import Header from './header/Header';
import '../styles/App.css';

export class KommunalLayout extends Component {
  render() {

    const { children } = this.props;
    return (
      <div className="kommunal-wrapper">
        <div className="kommunal-main-panel">
          <Header />
          <div className="kommunal-content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default KommunalLayout;
