import React, { PureComponent } from 'react';

class ProjectsCard extends PureComponent {

  render() {
    return (
      <div className="card">
        <div className="card-header card-header-bb">
          <div className="card-text">
            <p className="card-title card-title-s">Mina åtaganden</p>
          </div>
        </div>
        <div className="card-body">
          <table className="table mb-0">
            <thead>
              <tr>
                <th className="text-center">Typ</th>
                <th>Aktivitet</th>
                <th>Sajt/Projekt </th>
                <th>Klart</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                    <i className="fas fa-share-alt"></i>
                </td>
                <td>Byta samtliga bilder </td>
                <td>Projekt Beata </td>
                <td>2018-10-11</td>
              </tr>
              <tr>
                <td className="text-center">
                    <i className="fas fa-list-ul"></i>
                </td>
                <td>Skapa nya VVS-ritningar </td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-12</td>
              </tr>
              <tr>
                <td className="text-center">
                    <i className="fas fa-list-ul"></i>
                </td>
                <td>Lägga in EL-ritningar för bottenvåningen</td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-15</td>
              </tr>
              <tr>
                <td className="text-center">
                    <i className="fas fa-list-ul"></i>
                </td>
                <td>Lägga in EL-ritningar för bottenvåningen</td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-20</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Skapa enlinjeschema Nätstation</td>
                <td>KL Industris projekt </td>
                <td>2018-10-20</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-share-alt"></i>
                </td>
                <td>Byta samtliga bilder </td>
                <td>Projekt Beata </td>
                <td>2018-10-11</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Skapa nya VVS-ritningar </td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-12</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Lägga in EL-ritningar för bottenvåningen</td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-15</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Lägga in EL-ritningar för bottenvåningen</td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-20</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Skapa enlinjeschema Nätstation</td>
                <td>KL Industris projekt </td>
                <td>2018-10-20</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Lägga in EL-ritningar för bottenvåningen</td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-20</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Skapa enlinjeschema Nätstation</td>
                <td>KL Industris projekt </td>
                <td>2018-10-20</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-share-alt"></i>
                </td>
                <td>Byta samtliga bilder </td>
                <td>Projekt Beata </td>
                <td>2018-10-11</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Skapa nya VVS-ritningar </td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-12</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Lägga in EL-ritningar för bottenvåningen</td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-15</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Lägga in EL-ritningar för bottenvåningen</td>
                <td>Projekt Bygghandboken</td>
                <td>2018-10-20</td>
              </tr>
              <tr>
                <td className="text-center">
                  <i className="fas fa-list-ul"></i>
                </td>
                <td>Skapa enlinjeschema Nätstation</td>
                <td>KL Industris projekt </td>
                <td>2018-10-20</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
          
    );
  }
}

export default ProjectsCard;