import React, { PureComponent } from 'react';

export class SiteMenu extends PureComponent {
  
  renderMenus = () => (
    <ul className="nav nav-menu">
      <li><a href="/dashboard">Översikt</a></li>
      <li><a href="/bestalla">Beställa</a></li>
      <li><a href="http://wilma.unq.se/views/FileViewSiemens">Dokument</a></li>
      <li><a href="#test">Orderhistorik</a></li>
      <li className="nav-item dropdown dropdown-views">
        <a
          href="#test"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Hjälp
        </a>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar-alert">
          <a className="dropdown-item" href="#test">Om</a>
          <a className="dropdown-item" href="#test">Kontakt</a>
          <a className="dropdown-item" href="#test">Synpunkter / Support</a>
        </div>
      </li>
    </ul>
  );
  

  render() {
    return (
      <div className="menubar-wrapper" id="header-site">
        {this.renderMenus()}
      </div>
    );
  }
}

export default SiteMenu;
