import React, { PureComponent } from 'react';

class CartCard extends PureComponent {

  render() {
    return (
      <div className="card card-stats">
        <div className="card-body ">
          <div className="row align-items-center">
            <div className="col-5 col-md-4">
              <div className="icon-big text-center icon-warning">
                <i className="nc-icon nc-cart-simple text-success" />
              </div>
            </div>
            <div className="col-7 col-md-8">
              <div className="numbers">
                <p className="card-category">ORDRAR TOTALT</p>
                <p className="card-stat-date">augusti 2018</p>
                <p className="card-title">2,440</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CartCard;