import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { IDENTITY_CONFIG, METADATA_OIDC } from '../utils/authConst';
import config from '../config';
import logger from 'helpers/logger';

export default class AuthService {
  UserManager;

  accessToken;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC
      }
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded(user => {
      this.accessToken = user.access_token;
      localStorage.setItem('access_token', user.access_token);
      localStorage.setItem('id_token', user.id_token);
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token
      });
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError(e => {
      logger.debug('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      logger.debug('token expired');
      // TODO signedinSilent has error atm needs to be configured or dicussed
      // this.signinSilent();
      localStorage.clear();
      this.signinRedirect();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      '';
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      const respond = await this.UserManager.signinRedirectCallback();
      return respond;
    }
    return user;
  };

  parseJwt = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  setUserInfo = authResult => {
    const data = this.parseJwt(this.accessToken);

    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  setUser = data => {
    localStorage.setItem('userId', data.sub);
  };

  navigateToScreen = () => {
    // const redirectUri = !!localStorage.getItem("redirectUri")
    //   ? localStorage.getItem("redirectUri")
    //   : "/en/dashboard";
    // const language = "/" + redirectUri.split("/")[1];

    // window.location.replace(language + "/dashboard");
    window.location.replace('/app');
  };

  setSessionInfo = authResult => {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
  }

  isAuthenticated = () => {
    const accessToken = localStorage.getItem('access_token');
    return !!accessToken;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        logger.debug('signed in', user);
      })
      .catch(err => {
        logger.debug(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token')
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL || config.siteUrl);
    });
    this.UserManager.clearStaleState();
  };
}