/* eslint no-console: 0 */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'startup/redux';
import { ApolloProvider } from 'startup/apollo';
import ReduxToastr from 'react-redux-toastr';
import ModalDialog from 'components/common/dialogs/ModalDialog';

import { AuthProvider } from '../providers/authProvider';

import App from './App';

export class AppContainer extends Component {
  render() {
    return (
      <AuthProvider>
        <ApolloProvider>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <App />
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="bottom-right"
                transitionIn="bounceIn"
                transitionOut="bounceOut"
                progressBar
                closeOnToastrClick
              />
              <ModalDialog />
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </AuthProvider>
    );
  }
}

export default AppContainer;
