import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';

class ProductImageView extends PureComponent {

  render() {
    const { product } = this.props;
    return (
      <Row>
        <Col md="8">
          <img src={product.image} alt="product" />
        </Col>
        <div className="col-md-4 d-flex flex-column">
          <h5 className="product-name mb-2">Visitkort förbundskontoret</h5>
          <p className="product-desc mb-2">Används endast av Förbundskontorets anställda.</p>
          <dl className="product-info">
            <dt>Art.nr</dt>
            <dd>{product.version}</dd>
          </dl>
          <input type="submit" className="btn btn-sm btn-primary" value="Djur" />
          <div className="mt-auto text-right">
            <a href="#test" className="text-warning mr-3" data-toggle="tooltip" data-placement="top" title="Redigera">
              <i className="fa fa-edit align-middle" />
            </a>
            <a href="#test" className="text-primary mr-3" data-toggle="tooltip" data-placement="top" title="Ladder ner">
              <i className="fa fa-download align-middle" />
            </a>
            <a href="#test" className="text-primary mr-3" data-toggle="tooltip" data-placement="top" title="Lägg till bild">
              <i className="fa fa-image align-middle" />
            </a>
            <a href="#test" className="text-primary" data-toggle="tooltip" data-placement="top" title="Dela">
              <i className="fa fa-share-alt align-middle" />
            </a>
          </div>
        </div>
      </Row>
    );
  }
}

export default ProductImageView;