import React, { PureComponent } from 'react';
import { Nav } from 'reactstrap';
import DownloadNotif from './notifications/DownloadNotif';
import CartNotif from './notifications/CartNotif';
import BellNotif from './notifications/BellNotif';
import UserInfo from './notifications/UserInfo';

export class NotificationArea extends PureComponent {
  render() {
    const { user } = this.props;
    return (
      <Nav navbar>
        <DownloadNotif />
        <CartNotif />
        <BellNotif />
        <UserInfo user={user} />
      </Nav>
    );
  }
}

export default NotificationArea;