import React, { PureComponent } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card } from 'reactstrap';
import classnames from 'classnames';
import Switch from 'react-bootstrap-switch';

import ForHands from './ForHands';
import Filer from './Filer';

class DynamicForm extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  
  render() {
    return (
      <Card className="card-tabs card-tabs-v3 mb-0">
        <Card body className="card-body card-body-p-0">
          <div className="row mb-2 align-items-center">
            <div className="col-12">
              <label htmlFor="" className="fs-12 mr-1 fw-normal">Redigerbar (dynamisk): </label>
              <Switch
                onChange={() => console.log('test')}
                defaultValue
                onColor="info"
                offColor="info"
                className="bootstrap-switch"
              />
            </div>
          </div>
          <Nav tabs role="tablist">
            
            <NavItem className="w-auto">
              <NavLink
                className={classnames('py-2', { active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
              >
                <span className="card-tabs-title">Förhandsgranskning</span>
              </NavLink>
            </NavItem>
            <NavItem className="w-auto">
              <NavLink
                className={classnames('py-2', { active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
                <span className="card-tabs-title">Filer</span>
              </NavLink>
            </NavItem>
            <NavItem className="w-auto">
              <NavLink
                className={classnames('py-2', { active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
              >
                <span className="card-tabs-title">Personallistor</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab} className="tab-content px-3 py-3">
            <TabPane tabId="1">
              <ForHands />
            </TabPane>
            <TabPane tabId="2">
              <Filer />
            </TabPane>
            <TabPane tabId="3">
              <div className="card no-shadow bg-ltgray">
                <div className="card-body p-4">
                  <p className="fs-14 bg-success p-1 mb-0 d-inline">Välj Ingen lista</p>
                </div>
              </div>
              <div className="card no-shadow bg-ltgray">
                <div className="card-body p-4">
                  <p className="fs-14 mb-0">Välj Hämta uppgifter från uppladdad Excel-fil</p>
                </div>
              </div>
              <div className="card no-shadow bg-ltgray">
                <div className="card-body p-4">
                  <p className="fs-14 mb-1">Välj Test Visa Lista</p>
                  <p className="fs-14 mb-0">Välj Visitkort Visa Lista</p>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </Card>
        
      </Card>
    );
  }
}

export default DynamicForm;