/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input
} from 'reactstrap';

export class SiteDropdown extends PureComponent {

  render() {
    const sites = [
      { id: 1, name: 'Site 1' },
      { id: 2, name: 'Site 2' },
    ];
    return (
      <UncontrolledDropdown className="dropdown dropdown-workspace">
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup
          caret
          className="btn btn-secondary dropdown-toggle"
          color="primary"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
        >
          Välj arbetsyta här
        </DropdownToggle>
        <DropdownMenu
          aria-labelledby="dropdownMenuButton"
          right
          className="dropdown-menu"
        >
          <Form className="w-100">
            <InputGroup className="no-border">
              <Input placeholder="Sök..." />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <hr />
          { sites && sites.map(site => (
            <DropdownItem
              key={site.id}
              href="#"
              onClick={e => e.preventDefault()}
            >
              {site.name}
            </DropdownItem>
          ))}
          <hr className="mb-2" />
          <a href="#" className="workspace-edit-link"><i className="fa fa-edit" />Edit Sites</a>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}


export default SiteDropdown;