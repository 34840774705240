import React, { PureComponent } from 'react';

class DeviceCard extends PureComponent {

  render() {
    return (
      <div className="card">
        <div className="card-header card-header-bb">
          <div className="card-text">
            <p className="card-title card-title-s">Installed Devices</p>
          </div>
        </div>
        <div className="card-body">
          <canvas id="chartDonut1" className="ct-chart ct-perfect-fourth" />
        </div>
      </div>
    );
  }
}

export default DeviceCard;