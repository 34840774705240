const products = [
  {
    id: 1,
    name: 'Manual STG-800',
    price: 100,
    image: 'img/siemens-sgt-800.jpg',
    version: 'si-sgt800-02',
    uploadedBy: 'Ida Elofsson',
    createdAt: '2018-01-01',
    createdBy: 'Robert Lönn',
    uploadedAt: '2018-09-01',
    storage: '739 kB',
    description: 'Med en beprövad, lång meritlista av framgångsrika installationer runt om i världens så är gasturbinen SGT-800 ett utmärkt val för både industriell kraftproduktion och olje- och gasapplikationer.',
    lock: true,
  },
  {
    id: 2,
    name: 'Manual SGT-750',
    price: 150,
    image: 'img/siemens-sgt-750-feature.jpg',
    version: 'si-sgt750-01',
    uploadedBy: 'Ida Elofsson',
    createdAt: '2018-01-01',
    createdBy: 'Robert Lönn',
    uploadedAt: '2018-09-01',
    storage: '350 kB',
    description: 'Manual över Siemens SGT-750 - en lättviktsgasturbin som är konstruerad för att införliva storleks- och viktfördelarna som den flygmotorderivatbaserad gasturbinen ger, och samtidigt bibehålla robustheten och den långa livslängden som den traditionella tunga industriturginsturbin ger.',
    lock: false,

  },
  {
    id: 3,
    name: 'Manual SST-900',
    price: 200,
    image: 'img/siemens-sst-900.png',
    version: 'si-sst900-03',
    uploadedBy: 'Ida Elofsson',
    createdAt: '2018-01-01',
    createdBy: 'Robert Lönn',
    uploadedAt: '2018-09-01',
    storage: '120 kB',
    description: 'Siemens Model SST-900-ångturbin är en standardturbinlösning med kort leveranstid tack vare sin standarddesign. Fördefinierade turbinmoduler möjliggör korta tillverkningsperioder, kostnadseffektiv materialtillförsel och snabb leverans från fabriken.',
    lock: true,
  },
];

export default products;