import React, { PureComponent } from 'react';
import Me from 'graphql/components/Me';

class UserCard extends PureComponent {

  render() {
    return (
      <Me>{({ me }) =>
        <div className="card card-user-info">
          <div className="card-body ">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="user text-center">
                  <div className="photo mb-2">
                    <img src="img/faces/ayo-ogunseinde-2.jpg" alt="current user" />
                  </div>
                  <h4 className="user-name">{me.firstname}{me.lastname}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      }</Me>
      
      
    );
  }
}

export default UserCard;