import React, { PureComponent } from 'react';

class Filer extends PureComponent {
  render() {
    return (
      <>
        <div className="row mb-4">
          <div className="col-md-9">
            <div className="form-group mb-2">
              <label htmlFor="" className="d-block fs-14 mb-1">Visningsfil <span className="ml-2 fs-12 fw-normal">Fil som används till visning och förhandsgrandskning</span></label>
              <select className="selectpicker w-100" multiple="multiple" data-max-options="2" title="Välj fil">
                <option>broschyr3.pdf</option>
                <option>broschyr2_korr_byran.pdf</option>
              </select>
            </div>
            <div className="form-group mb-2">
              <label htmlFor="" className="d-block fs-14 mb-1">Beställningsfil <span className="ml-2 fs-12 fw-normal">Fil som skickas till leverantören vid beställning</span></label>
              <select className="selectpicker w-100" multiple="multiple" data-max-options="2" title="Välj fil">
                <option>broschyr3.pdf</option>
                <option>broschyr2_korr_byran.pdf</option>
              </select>
            </div>
            <div className="form-group mb-2">
              <label htmlFor="" className="d-block fs-14 mb-1">Filer <span className="ml-2 fs-12 fw-normal">Övriga filer. Dra och släpp filerna om du vill flytta dem</span></label>
              <div className="form-control filer-list-wrap">
                <div role="tablist" aria-multiselectable="true" className="card-collapse">
                  <div className="card card-plain">
                    <div className="card-header py-2" role="tab">
                      <a data-toggle="collapse" href="#filer-2-1" aria-expanded="true" aria-controls="filer-2-1" className="fs-12">
                        broschyr3.pdf
                        <i className="nc-icon nc-minimal-down" />
                      </a>
                    </div>
                    <div id="filer-2-1" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
                      <div className="card-body py-1">
                        <ul className="filer-list">
                          <li>
                            3: broschyr3.pdf <a href="#test"><i className="fa fa-trash" /></a>
                          </li>
                          <li>
                            2: broschyr3.pdf <a href="#test"><i className="fa fa-trash" /></a>
                          </li>
                          <li>
                            1: broschyr3.pdf <a href="#test"><i className="fa fa-trash" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card card-plain">
                    <div className="card-header py-2" role="tab">
                      <a data-toggle="collapse" href="#filer-2-2" aria-expanded="true" aria-controls="filer-2-2" className="fs-12">
                        bilaga2.pdf
                        <i className="nc-icon nc-minimal-down" />
                      </a>
                    </div>
                    <div id="filer-2-2" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
                      <div className="card-body py-1">
                        <ul className="filer-list">
                          <li>
                            3: broschyr3.pdf <a href="#test"><i className="fa fa-trash" /></a>
                          </li>
                          <li>
                            2: broschyr3.pdf <a href="#test"><i className="fa fa-trash" /></a>
                          </li>
                          <li>
                            1: broschyr3.pdf <a href="#test"><i className="fa fa-trash" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="" className="d-block fs-14 mb-1">Bilagor <span className="ml-2 fs-12 fw-normal">Filer som bifogas när artikeln laddas ned eller beställs</span></label>
              <select className="selectpicker w-100" multiple="multiple" data-max-options="2" title="Välj fil">
                <option>broschyr3.pdf</option>
                <option>broschyr2_korr_byran.pdf</option>
              </select>
            </div>
            <div className="form-group">
              <div className="row align-items-center mb-1">
                <div className="col-md-8">
                  <div className="form-control">
                    <span className="btn btn-file btn-sm m-0">
                      <span className="fileinput-new">Välj fil</span>
                      <input type="file" name="..." />
                    </span>
                  </div>
                </div>
                <div className="col-md-4 pl-0">
                  <input type="submit" className="btn btn-primary btn-sm btn-normal-f m-0" defaultValue="Ladda up" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="fs-12 mb-0"><em>OBS! Om det finns en fil med samma namn så ersätts denna.</em></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="product-img-wrap mb-2">
              <div className="product-img-hover">
                <a href="#test" className="btn btn-primary btn-sm btn-normal-f m-0 mb-2">Ändra miniatyrbild</a>
              </div>
              <img src="img/thumb-170.png" alt="" />
            </div>
            <div className="bg-ltgray p-2">
              <p className="fs-10 mb-0">
                Miniatyrbilden är en förminskad bild som ger en förhandsvisning av artikeln eller filinnehållet.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <p className="fs-12 mb-1"><strong>Instruktioner</strong></p>
            <p className="fs-12 mb-0">Dra och släpp en fil från din dator till området Visningsfil, Beställningsfil, Filer eller Bilagor. Du kan i efterhand dra och släppa en fil mellan olika områden.</p>
          </div>
          <div className="col-md-3">
            <a href="#test" className="btn btn-primary btn-sm d-block m-0 mb-2 w-100">Exportera mall</a>
            <a href="#test" className="btn btn-primary btn-sm d-block m-0 w-100">Spara mall</a>
          </div>
        </div>
      </>
    );
  }
}

export default Filer;