/* eslint-disable no-multi-assign */
// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.1.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import './styles/App.css';

import AppContainer from './layout/AppContainer';
import * as serviceWorker from './serviceWorker';

const render = Component => {
  ReactDOM.render(<Component />, document.getElementById('root'));
};

render(AppContainer);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
