import React, { PureComponent } from 'react';
import {
  NavItem,
  NavLink
} from 'reactstrap';

export class DownloadNotif extends PureComponent {
  render() {
    return (
      <NavItem>
        <NavLink
          className="btn-magnify"
          href="#pablo"
          onClick={e => e.preventDefault()}
        >
          <i className="nc-icon nc-album-2" />
          <p>
            <span className="d-lg-none d-md-block">Download Images</span>
          </p>
        </NavLink>
      </NavItem>
    );
  }
}

export default DownloadNotif;