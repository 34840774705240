import React, { Component } from 'react';
import Header from './demoHeader/Header';
import '../styles/siemens.css';

export class DemoLayout extends Component {
  render() {
    
    const { children } = this.props;
    return (
      <div className="wrapper">
        <div className="main-panel">
          <Header />
          <div className="content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default DemoLayout;
